import React from 'react'
import { graphql } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "@emotion/styled"

import Layout from '../components/layout'
import Seo from '../components/seo'

const Article = styled.article`
position: relative;
`
const Banner = styled.section`
width: 100%;
max-height: 100vh;
overflow: hidden;
position: relative;
background-color: rgb(200,200,200);
.title {
    position: absolute;
    top: 35%;
    width: 100%;
    color: #fff;
    z-index: 90;
    h1 {
        font-family: "Freight Big Black Regular";
        font-size: 4.5rem;
        line-height: 1em;
        text-align: center;
        margin: 0px auto 10px auto;
        padding: inherit;
        background-color: inherit;
        width: 80%;
        letter-spacing: -1px;
    }
    h2 {
        font-family: "FreightText Light Italic";
        letter-spacing: 1px;
        font-size: 28px;
        margin: 0px auto;
        width: 80%;
    }
}
.bannerimage {
    position: relative;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
        & > div:first-of-type {
            width: 100vw;
            max-width: none !important;
        }
        img {
            object-fit: cover;
            height: 100vh;
            width: 100vw;
        }    
    }
    .shade {
        background-color: rgba(0,0,0,.4);
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 10;
    }
  }
}
`
const Articlebody = styled.section`
margin: 0px auto;
padding-top: 120px;
`
const Textwrap = styled.div`
width: 60vw;
margin: 0px auto;
padding: 0px 0px 40px 0px;
text-align: left;
h3 {
  font-family: "Freight Big Black Regular";
  font-size: 3rem;
  line-height: 1em;
  margin-bottom: 26px;
  &::before {
    content: '';
    display: block;
    width: 15%;
    margin: 40px auto 60px 0px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.3);
  }
}
p {
  padding-bottom: 26px;
  color: rgba(0,0,0,.8);
  a {
    border-bottom: solid 3px rgba(0,0,0,0.1);
    font-weight: 450;
    padding-bottom: 3px;
    &:hover {
      color: rgba(0,0,0,1);
      border-bottom-color: rgba(255,222,23,1);
    }
  }
  strong {
    font-weight: 800;
  }
  em {
    border-bottom: solid 1px rgba(0,0,0,0.25);
    font-variation-settings: 'slnt' -8;
  }
}
pre {
  border-left: solid 1px rgba(0,0,0,0.2);
  padding-left: 40px;
  padding-bottom: 26px;
  margin-bottom: 26px;
  color: rgba(0,0,0,.8);
}
`

const Partner = ({data}) => {
    if (!data) return null
    const doc = data.prismicPartner.data

    return (
        <Layout>
          <Seo title={doc.title.text} />
            <Article>
                <Banner>
                <div className="title">
                        <PrismicRichText field={doc.title.richText} />
                        <PrismicRichText field={doc.subtitle.richText} />
                </div>
                <div className="bannerimage">
                    <GatsbyImage image={doc.banner_image.gatsbyImageData} />
                     <div className="shade"></div>
                </div>
                </Banner>
                <Articlebody>
                <Textwrap><PrismicRichText field={doc.text.richText} /></Textwrap>
                </Articlebody>
            </Article>

        
        </Layout>

    )
}

export const pageQuery = graphql`
query ($id: String) {
    prismicPartner(id: {eq: $id}) {
      data {
        title {
          richText
          text
        }
        text {
          richText
        }
        subtitle {
          richText
        }
        banner_image {
          gatsbyImageData
        }
      }
    }
}
`
export default Partner
